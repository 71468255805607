import React, { useContext, useState, useEffect } from "react"
import { database } from '../services/firebase';

const VendedoresListContext = React.createContext()

export function useVendedores() {
  return useContext(VendedoresListContext);
}

export function VendedoresProvider({ children }) {

  const [vendedoresList, setVendedoresList] = useState([]);
  const [usuariosvendedores, setusuariosvendedores] = useState([]);

  useEffect(() => {  
    const vendedoresRef = database.ref(`/vendedores/`);
      vendedoresRef.on('value', (snapshot) => {      
        const vendedores = snapshot.val();
        const vendedoresList = [];
        for (let id in vendedores) {
          vendedoresList.push ({ id, ...vendedores[id] });
        }
        setVendedoresList(vendedoresList);
      });
      return () => {vendedoresRef.off('value');}
    }, []);

    vendedoresList.sort(function (a, b) {
      return (a.label > b.label) ? 1 : ((b.label > a.label) ? -1 : 0);
    });

      useEffect(() => {  
        const usuariosRef = database.ref(`/usuarios/`);
        usuariosRef.on('value', (snapshot) => {
            const vendedores = snapshot.val();

            const vendedoresList = [];
            for (let id in vendedores) {
              vendedoresList.push ({ id, ...vendedores[id] });
            }

            const permissao = vendedor => vendedor.permissao === 'vendedor';
            const usuarios = vendedoresList.filter(permissao);
            const analisdados = vendedor => vendedor.analise === true;
            const vendedoresanalisados = usuarios.filter(analisdados);

            setusuariosvendedores(vendedoresanalisados);
          });
        return () => {
          usuariosRef.off('value');
        }
      }, []);

      const commonIds = usuariosvendedores
        .filter(usuario => vendedoresList.some(vendedor => vendedor.id === usuario.id))
        .map(usuario => usuario.id);
      console.log('IDs comuns:', commonIds);

      const vendedoresparabag = vendedoresList.filter(vendedor => commonIds.includes(vendedor.id));

      console.log(vendedoresparabag)

    const value = {
      vendedoresList, 
      vendedoresparabag
    }

  return (
    <VendedoresListContext.Provider value={value}>
      {children}
    </VendedoresListContext.Provider>
  )

}